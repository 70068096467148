<template lang="pug">
.relative-position.bg-secondary.b-radius-10(ref="slide")
    router-link.card.cursor-pointer.relative-posiiton.row.justify-between.no-wrap.font-16.box-border(
        flat
        :to="city ? `/${city.slug}/product/${data.url}` : `/product/${data.url}`"
        :title="`${data.name} ${ data.subtitleShort } (${ data.manufacture }, ${ data.country })`"
        :style="cardStyle")
        .absolute-top-left.justify-center.relative-position.row.cold-recipe
            q-icon(
                v-if="data.isPrescription && !data.isErp"
                name="img:rx.svg"
                size="20px"
                color="negative").cold-recipe-icon
                base-tooltip(title="По рецепту")
            q-icon(
                v-if="data.isPrescription && data.isErp"
                name="img:rx_e.svg"
                size="20px"
                color="negative").cold-recipe-icon
                base-tooltip(title="По электронному рецепту")
            q-icon(
                v-if="data.cold"
                name="etabl:frost"
                size="20px"
                color="light-blue").cold-recipe-icon
                base-tooltip(title="Особые условия хранения")
            q-icon(
                v-if="isActivAccessibility ? false : freeDelivery && (data.qDs || data.isPreorder)"
                :name="data.delivery == 1 ? 'etabl:delivery_one' : 'etabl:delivery_two'"
                size="20px"
                :color="data.delivery == 1 ? 'positive' : 'deep-purple-6'")
                base-tooltip(:title="data.delivery == 1 ? 'Бесплатная доставка' : 'Бесплатная доставка при заказе от 2 товаров'")
            q-icon(
                v-if="data.truCode"
                name="img:fss.svg"
                size="20px"
                color="light-blue").cold-recipe-icon
                base-tooltip(fss :title="fssTitle")
        .absolute-top-right.justify-center.favourite
            q-icon(
                v-if="!data.discount || !recommendationId"
                @click.prevent.default="updateFavourite"
                name="etabl:heart"
                size="20px"
                :color="isFavourite ? 'negative' : 'grey-7'")
                base-tooltip(title="В избранное")
            .b-radius-16.border-negative.font-12.text-negative.text-weight-medium.q-px-xs.q-mt-sm(v-if="data.discount && recommendationId")
                | {{ data.discount }}
        .q-pa-md.card__img(v-if="!isActivAccessibility")
            .row.items-center.justify-center.full-height.relative-position
                img.full-height.swiper-lazy(
                    ref="slideImg"
                    :src="img"
                    style="object-fit: contain; max-width: 100%; max-height: 70px"
                    :title="`${data.name} ${ data.subtitleShort } (${ data.manufacture }, ${ data.country })`"
                    :alt="`${data.name} ${ data.subtitleShort } (${ data.manufacture }, ${ data.country })`")
        .column.q-pt-sm.q-pr-xl.card__info
            p.font-16.text-positive.text-weight-bold.q-ma-none#name.line-height-22
                | {{ data.name }}
                base-tooltip(:title="data.name")
            p.font-14.text-primary.q-mt-xs.q-mb-none(v-if="subtitle")
                | {{ data.subtitleShort }}
                base-tooltip(:title="data.subtitleShort")
            p.text-weight-medium.q-ma-none(v-if="count")
                template(v-if="!data.isStock && !data.isPreorder")
                    span.text-grey
                        | нет в наличии
            template(v-if="!notAvailable")
                .row.text-positive.text-weight-bold.q-ma-none.items-center.card__price.justify-between(:class="isActivAccessibility ? '' : 'q-mt-sm'")
                    div
                        template(v-if="data.isMarker || recommendationId")
                            span.q-mr-sm.q-pl-sm.font-14.card__price-new(
                                v-if="data.priceCrossed"
                                style="text-decoration: line-through;")
                                | {{ new Intl.NumberFormat('ru-RU').format(data.priceCrossed) }} 
                                span.roboto ₽
                            span.text-weight-bold.font-16.card__price-old
                                | {{ new Intl.NumberFormat('ru-RU').format(data.price) }} 
                                span.roboto ₽
                        template(v-else)
                            span.text-weight-bold.font-16.card__price-old
                                | от {{ new Intl.NumberFormat('ru-RU').format(data.price) }} 
                                span.roboto ₽
                    .row.items-center            
                        div
                            // q-icon.delivery_icon(
                            //     v-if="delivery && !freeDelivery && data.qDs"
                            //     name="etabl:car_delivery"
                            //     size="24px"
                            //     color="grey-9")
                            //     base-tooltip(title="Возможна доставка на дом")
                        div(v-if="notAvailable").q-mb-sm.q-pa-none.q-mt-sm.q-pr-lg
                            p.q-ma-none.text-weight-medium.text-grey-8 нет в наличии
                        div(v-else :class="basketSlider ? 'd-block' : 'd-none'").justify-between.relative-position.q-pa-none.d-sm-flex.card__busket
                            base-btn(
                                v-if="reorder"
                                label="Заменить"
                                width="90"
                                height="24"
                                bradius="10"
                                font_size="12"
                                lineHeight="12px"
                                color="purple-5"
                                @click.prevent.stop="reorderProduct(data)")
                            template(v-else)
                                base-btn(label="Только в аптеке"
                                    v-if="data.limit === 0 || data.pku"
                                    color="accent"
                                    text_color="positive"
                                    height="24"
                                    loading_color="secondary"
                                    :loading="loading"
                                    :disable="loading"
                                    width="90"
                                    bradius="10"
                                    font_size="12"
                                    lineHeight="12px"
                                    @click.prevent.stop="")
                                template(v-else)
                                    base-btn(:label="recommendationId ? 'Заменить' : 'В корзину'"
                                        v-if="!inCart || !data.inBasket"
                                        height="24"
                                        loading_color="secondary"
                                        :color="prodPage ? 'positive' : 'purple-5'"
                                        :loading="loading"
                                        :disable="loading"
                                        width="90"
                                        bradius="10"
                                        font_size="12"
                                        lineHeight="12px"
                                        @click.prevent.stop="addToCart")
                                    base-btn(
                                        label="В корзине"
                                        v-else
                                        height="24"
                                        loading_color="secondary"
                                        color="grey-9"
                                        :loading="loading"
                                        :disable="loading"
                                        width="90"
                                        bradius="10"
                                        font_size="12"
                                        lineHeight="12px"
                                        @click.prevent.stop="")
                                    //- template(v-else)
                                    //-     .row.items-center.q-py-xs.justify-start.cursor-pointer(
                                    //-         width="90px"
                                    //-         @click.prevent.stop="goToCart")
                                    //-     .row.justify-end.card__counter
                                    //-         q-btn.justify-center.items-center.card__button.card__button-min.cursor-pointer(
                                    //-             :disable="!!recommendationId && !!inCart && amount === 1"
                                    //-             unelevated
                                    //-             rounded
                                    //-             @click.prevent.stop="setAmount(0)") &ndash;
                                    //-         .row.justify-center.items-center.card__count {{ amount }}
                                    //-         q-btn.justify-center.items-center.card__button.card__button-plus.cursor-pointer(
                                    //-             v-if="!preventAmoutUp"
                                    //-             unelevated
                                    //-             rounded
                                    //-             @click.prevent.stop="setAmount(1)") +
                                    //-         q-btn.justify-center.items-center.card__button.card__button__disable.cursor-pointer(
                                    //-             v-else
                                    //-             unelevated
                                    //-             rounded
                                    //-             @click.prevent.stop="") +
                                    //-             base-tooltip(
                                    //-                 style="max-width: 300px;"
                                    //-                 title="Количество ограничено")
</template>

<script>
import { useQuasar } from 'quasar';
import { ref, computed, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import BaseBtn from 'components/Base/BaseBtn.vue';
import BaseTooltip from 'components/Base/BaseTooltip.vue';
import BaseSpiner from 'components/Base/BaseSpiner.vue';

export default {
    name: 'PreviewDesktop',
    components: {
        BaseBtn,
        BaseTooltip,
        BaseSpiner,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        subtitle: {
            type: Boolean,
            default: () => true,
        },
        manufacture: {
            type: Boolean,
            default: () => true,
        },
        country: {
            type: Boolean,
            default: () => false,
        },
        count: {
            type: Boolean,
            default: () => true,
        },
        countSlides: {
            type: Number,
        },
        index: {
            type: Number,
        },
        countVisibleSlides: {
            type: Number,
        },
        styles: {
            type: String,
        },
        basket: {
            type: Boolean,
            default: () => true,
        },
        variant: {
            type: String,
        },
        pickupVariant: {
            type: String,
        },
        recommendationId: {
            type: String,
        },
        reorder: {
            type: Boolean,
            default: () => false,
        },
        prodPage: {
            type: Boolean,
            default: () => false,
        },
        basketSlider:{
            type: Boolean,
            default: ()=> false,
        },
        viewCart:{
            type: Boolean,
            default: ()=> false,
        },
    },
    setup(props, { emit }) {
        const $q = useQuasar();
        const store = useStore();
        const router = useRouter();
        const amount = ref(1);
        const loading = ref(false);
        const tooltipe = ref(true);
        const amountTimer = ref(null);
        const imgLink = ref(null);
        const slide = ref(null);
        const slideImg = ref(null);
        const isLocalFavourite = ref(false);

        const isActivAccessibility = computed(() => store.getters['styles/isActiv']);

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);

        const userAuthorized = computed(() => store.getters['user/USER_AUTHORIZED']);

        const isFavourite = computed(() => {
            if(userAuthorized.value) {
                return isLocalFavourite.value;
            } else {
                const favourites = store.getters['localStorage/FAVOURITES'];
                return favourites?.find((item) => item === props.data.id);
            }
        });

        const cartProducts = computed(() => store.getters['localStorage/BASKET_PRODUCTS']);

        const notAvailable = computed(() => {
            return !!(!props.data.priceNew && !props.data.priceOld && !props.data.minPrice)
                || !!(!props.data.maxPreorderCount && !props.data.maxStockCount);
        });

        const cardStyle = computed(() => {
            if(props.basket) {
                return 'height: 106px; width: 330px';
            } else {
                return 'height: 106px; width: 366px';
            }
        });

        const priceFrom = computed(() => {
            if (props.data.maxStockCount && !props.data.maxPreorderCount) {
                return props.data.minPrice;
            }

            return false;
        });

        const inCart = ref(false);

        const img = computed(() => props.data.previewImage);

        const letsWarning = () => {
            $q.notify({
                color: 'warning',
                message: 'Произошла ошибка! Повторите позднее',
            });
        };

        const updateFavourite = async () => {
            if (isFavourite.value) {
                userAuthorized.value
                    ? deleteProductFromUserFav()
                    : store.commit('localStorage/DELETE_FAVOURITE_PRODUCT', props.data.id);
            } else {
                userAuthorized.value
                    ? addProductToUserFav()
                    : store.commit('localStorage/ADD_FAVOURITE_PRODUCT', props.data.id);
            }
        };

        // // добавление/удаление из избранного
        const deleteProductFromUserFav = async () => {
            try {
                await store.dispatch('user/DELETE_FAVOURITE_PRODUCT', props.data.id);
                await store.commit('user/DELETE_USER_FAVOURITE_PRODUCT', props.data.id);
                isLocalFavourite.value = false;
            } catch (e) {
                isLocalFavourite.value = true;
            }
        };

        const addProductToUserFav = async () => {
            try {
                await store.dispatch('user/ADD_FAVOURITE_PRODUCT', props.data.id); 
                isLocalFavourite.value = true;
            } catch (e) {
                isLocalFavourite.value = false;
            }
        };

        const goToCart = () => {
            router.push('/basket');
        };

        const addToCart = async () => {

            userAuthorized.value
                ? await putProductInCart()
                : await putProductInLocalCart();
            try {
                store.commit('basketNew/SET_BASKET_LOADING', true);
                if (props.viewCart){
                    store.commit('basketNew/SET_BASKET_LOADING', true);
                    await store.dispatch('basketNew/GET_BASKET');
                }
                ym(72234883,'reachGoal','klik-korzina');

                window.dataLayer.push({
                    'ecommerce': {
                        'currencyCode': 'RUB',
                        'add' : {
                            'products' : [
                                {
                                    'id': props.data.id,
                                    'name': props.data.name + props.data.subtitleShort,
                                    'brand': props.data.manufacture,
                                    'price': props.data.price,
                                },
                            ],
                        },
                    },
                });
                store.commit('basketNew/SET_BASKET_LOADING', false);

            } catch {}
        };

        // добавление товара в корзину для авторизоаванных
        const putProductInCart = async () => {
            loading.value = true;
            const in_basket = false;

            if (amount.value && amount.value > 0) {
                try {
                    await store.dispatch('basketNew/PUT_PRODUCT_IN_BUSKET', {
                        product: {
                            id: props.data.id,
                            amount: amount.value,
                            checked: true,
                        },
                        in_basket,
                    });

                    if(props.basket && props.variant == 'pickup') {
                        await store.dispatch('basketNew/GET_PICKUP_BASKET', props.pickupVariant);
                    }
                    if(props.basket && props.variant == 'courier') {
                        await store.dispatch('basketNew/GET_DELIVERY_BASKET', props.pickupVariant);
                    }
                } catch (error) {
                    $q.notify({
                        color: 'negative',
                        message: 'Произошла ошибка. Повторите попытку позже',
                    });
                };
            }

            if(amount.value == 1 && props.recommendationId && !inCart.value) {
                emit('cleanRecom');
                await store.dispatch('basketNew/CHANGE_BASKET_PRODUCT_AMOUNT', {
                    id: props.recommendationId,
                    count: 0,
                    variant: props.variant,
                    date: props.pickupVariant,
                });
            }

            inCart.value = true;
            
            loading.value = false;
        };

        const putProductInLocalCart = async (id = props.data.url) => {
            loading.value = true;

            const payload  = await store.dispatch('products/GET_PRODUCT_BY_SLUG', { id });
            store.commit('localStorage/ADD_TO_CART', payload);

            inCart.value = true;

            loading.value = false;
        };

        // удаление товара из корзины для авторизоаванных
        const delProductInCart = async () => {
            const in_basket = false;
            
            try {
                await store.dispatch('basket/BASKET_DEL_PRODUCT', { id: props.data.id, in_basket });

                inCart.value = false;
                if (props.viewCart){
                    store.commit('basketNew/SET_BASKET_LOADING', true);
                    await store.dispatch('basketNew/GET_BASKET');
                    store.commit('basketNew/SET_BASKET_LOADING', false);
                }
                try {

                    window.dataLayer.push({
                        'ecommerce': {
                            'currencyCode': 'RUB',
                            'remove' : {
                                'products' : [
                                    {
                                        'id': props.data.id,
                                        'name': props.data.name + props.data.subtitleShort,
                                        'brand': props.data.manufacture,
                                        'price': props.data.price,
                                    },
                                ],
                            },
                        },
                    });
                } catch {}
            } catch (error) {
                $q.notify({
                    color: 'negative',
                    message: error.message,
                });
            };
        };
        
        const setAmount = async (pm) => {
            if (pm) {
                amount.value++;
            } else {
                if (!(amount.value - 1) || amount.value - 1 < 0) {
                    userAuthorized.value
                        ? await delProductInCart()
                        : store.commit('localStorage/DELETE_ITEMS', props.data.id);
                    amount.value = 1;
                    inCart.value = false;
                    return;
                } else {
                    amount.value--;
                };
            };

            if (userAuthorized.value) {
                /**
                 * Если пользователь авторизован
                 * ставим задержку на отправку данных о количестве товара в корзине в 1 сек.
                 */
                
                if (amountTimer.value) {
                    clearTimeout(amountTimer.value);
                }
                amountTimer.value = setTimeout(await putProductInCart, 300);
            } else {
                // если пользователь НЕ авторизован
                // сохраняем кол-во товара локально в браузере
                store.commit('localStorage/SET_AMOUNT', {
                    id: props.data.id,
                    amount: amount.value,
                });
            }
        };

        const preventAmoutUp = computed(() => {
            if(props.data.limit < 0) {
                return amount.value >= props.data.totalCount ;
            } else if(props.data.limit == 0 || props.data.pku) {
                return false;
            } else if(props.data.limit > 0) {
                return amount.value >= props.data.limit;
            }
            return false;
        });

        const freeDelivery = computed(() => {
            return props.data.delivery && !props.data.cold && !props.data.isPrescription;
        });

        const delivery = computed(() => {
            return props.data.isStock && !props.data.cold && !props.data.isPrescription;
        });

        const checkLocalBasket = () => {
            let localProd = cartProducts.value?.find((item) => item.id === props.data.id);

            if(localProd) {
                amount.value = localProd.amount;
                inCart.value = true;
            } else {
                amount.value = 1;
                inCart.value = false;
            };
        };

        const checkProdInBasket = () => {
            if(props.data.inBasket) {
                amount.value = props.data.inBasket;
                inCart.value = true;
            } else {
                amount.value = 1;
                inCart.value = false;
            }
        };

        const fssTitle = computed(() => {
            return 'Этот товар можно оплатить в аптеках сети партнера "Табле.Точка" с помощью электронного сертификата ФСС, картой «МИР».';
        });

        const reorderProduct = (product) => {
            emit('reorderProduct', product);
        };

        onMounted(() => {
            if(!userAuthorized.value) {
                checkLocalBasket();
            } else {
                checkProdInBasket();
            }

            if(props.data.isFavourite)
                isLocalFavourite.value = true;

            emit('setSlideHeight', slide.value.clientHeight);
        });

        return {
            slide,
            inCart,
            slideImg,
            cardStyle,
            isFavourite,
            updateFavourite,
            addToCart,
            img,
            notAvailable,
            priceFrom,
            goToCart,
            setAmount,
            amount,
            loading,
            preventAmoutUp,
            delivery,
            freeDelivery,
            checkLocalBasket,
            checkProdInBasket,
            fssTitle,
            city,
            isActivAccessibility,
            reorderProduct,
        };
    },
};
</script>

<style lang="scss" scoped>
.card {
    width: 100%;
    border: none;
    transition: border-color 0.28s cubic-bezier(0.4, 0, 0.2, 1) !important;
    text-decoration: none;
    border-radius: 10px;
    border: 1px solid #0000001f;

    @media screen and (max-width: $breakpoint-mobile) {
        border: none;
    }

    p {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @media screen and (max-width: $breakpoint-mobile) {
            font-size: 14px;
            line-height: 16px;
        }
    }

    &__img {
      max-width: 100px;
      margin: 0 auto;
      width: 100%;
      height: 100%;

    }

    &__info {
        flex-grow: 1;
        max-width: 70%;
        @media (max-width: 1280px) {
            flex-grow: 1;
        }

        &-manufacturer {
            @media screen and (max-width: $breakpoint-mobile) {
                margin-bottom: 7px !important;
            }
        }
    }

    &__amount {
        font: {
            size: 14px;
            weight: 600;
        }

        & > span {
            text-align: center;
            margin-top: -2px;
        }

        @media (max-width: 1280px) {
            border-radius: 10px;
        }
    }

    &__counter {
        width: 90px;
        max-height: 100%;
        border-radius: 20px;
        overflow: hidden;
        color: $primary;
        top: 0px;
        right: 0px;

        font: {
            size: 12px;
            weight: 500;
        }
        @media (max-width: 960px) {
            margin-right: 0px;
        }
    }

    &__count {
        width: 30px;
        height: 24px;
        background-color: $app-color-background;
    }

    &__button {
        width: 30px;
        min-height: 100%;
        background-color: $accent;
        color: $primary;
        text-transform: none;
        border-radius: 0;
        padding: 0 !important;

        font: {
            size: 14px;
            weight: 400;
        }

        &__disable {
            color: $medium-gray;
        }
    }

    &__price {
        @media screen and (max-width: $breakpoint-mobile) {
            flex-grow: 1;
            display: flex;
            padding-bottom: 6px;
            font: {
                size: 14px !important;
            }
        }

        &-from {
            @media screen and (max-width: $breakpoint-mobile) {
                font-size: 18px !important
            }
        }

        &-old {
            @media screen and (max-width: $breakpoint-mobile) {
                font-size: 18px !important
            }
        }

        &-new {
            @media screen and (max-width: $breakpoint-mobile) {
                margin-bottom: 1px;
            }
        }
    }

    @media screen and (max-width: $breakpoint-mobile) {
        box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.05) !important;
        border-radius: 8px;
        margin-bottom: 5px;
        align-items: flex-start;
    }
}

.card:hover {
    border-color: $positive;
}

.card :deep(.q-img) {
    width: 100%;
    height: 90px;
    margin-top: 34px;
}

.card :deep(.q-btn) {
    font-size: 14px;
    z-index: 2;
}

.box-border {
    box-sizing: border-box;

    @media screen and (max-width: $breakpoint-mobile) {
        position: relative;
    }
}

.cold-recipe {
    padding: 7px;
    z-index: 999;

    &-icon {
        margin-right: 7px;
    }
}

.delivery {
    padding: 7px;
    z-index: 999;

    &-icon {
        margin-left: 7px;
    }
}

.q-px-lg {
    @media screen and (max-width: $breakpoint-mobile) {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.q-card__actions {
    padding: 0;
}

.card__info {
    font-size: 18px;
    @media (max-width: 1280px){
        font-size: 14px
    }
}

.card__price {
    font-size: 18px;
    @media (max-width: 1280px){
        font-size: 14px
    }
}

.card__amount {
    font-size: 14px;

    @media (max-width: 1280px){
        font-size: 12px;
    }
}

.fav-button {
    z-index: 999;
    transition: $main-transition;
    height: 40px;
    border-radius: 20px;
    width: 40px;
    background: $grey-5;

    &:hover {
        transform: scale(1.1);
    }
}

.delivery_icon {
    margin-right: 24px;

    @media screen and (max-width: 1280px) {
        margin-right: 0;
    }
}

.favourite {
    padding: 3px 7px;
}
</style>
